<template>
  <el-switch
    v-bind="$attrs"
    v-on="$listeners"
    :active-color="activeColor"
    :inactive-color="inactiveColor"
  ></el-switch>
</template>
<script>
export default {
  name: 'SlSwitchButton',
  props: {
    activeColor: {
      type: String,
      default: '#13ce66'
    },
    inactiveColor: {
      type: String,
      default: '#ff4949'
    }
  }
}
</script>
<style lang="scss">
</style>
