<template>
  <div class="sl-space"></div>
</template>
<script>

export default {
  name: 'SlSpace'
}
</script>
<style lang="scss">
.sl-space {
  flex-grow: 1 !important;
  height: 0;
}
</style>
