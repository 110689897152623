import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
  button: {
    cancelText: '取 消',
    submitText: '提 交',
    registerText: '注 册',
    loginText: '登 录',
    saveText: '保 存',
    enterText: '确 定'
  },
  placeholder: {

  },
  ...zhLocale
}
