// OEM供应商菜单项
import ParentMenuRoute from '@/views/components/layout/ParentMenuRoute.vue'
import { FinanceManageRoutes } from './shared_routes'

// ODM供应商菜单项
const ROUTES = [
  {
    path: 'oem-delivery-manage',
    name: '发货管理',
    meta: {
      icon: 'icon-sl-delivery-manage',
      code: 'menu_goods_management'
    },
    component: ParentMenuRoute,
    children: [
      {
        path: 'pending-order-list',
        name: '待接单列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/PendingOrderList.vue')
      },
      {
        path: 'production-materials',
        name: '生产资料',
        meta: {
          icon: '',
          notMenu: true
        },
        component: () => import('@/views/oemViews/ProductionMaterials.vue')
      },
      {
        path: 'pending-delivery-list',
        name: '待发货列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/PendingDeliverOrderList.vue')
      },
      {
        path: 'delivery-list',
        name: '发货单列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/OemDeliveryList.vue')
      },
      {
        path: 'boxing-list',
        name: '装箱单列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/BoxingList.vue')
      }
    ]
  },
  {
    path: 'oem-order-manage',
    name: '订单管理',
    meta: {
      icon: 'icon-sl-order-manage',
      code: 'menu_goods_management'
    },
    component: ParentMenuRoute,
    children: [
      {
        path: 'production-order-list',
        name: '生产订单',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/ProductionOrderList.vue')
      },
      {
        path: 'production-order-detail',
        name: '生产订单详情',
        meta: {
          icon: '',
          notMenu: true
        },
        component: () => import('@/views/oemViews/productionOrderList/ProductionOrderDetail.vue')
      },
      {
        path: 'cost-review',
        name: '成本复核',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/CostReviewList.vue')
      }
    ]
  },
  FinanceManageRoutes,
  {
    path: 'oem-quality-manage',
    name: '质量管理',
    meta: {
      icon: 'icon-sl-quality-manage',
      code: 'menu_goods_management'
    },
    component: ParentMenuRoute,
    children: [
      {
        path: 'oem-defective-list',
        name: '质检次品',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/OemDefectiveList.vue')
      },
      {
        path: 'pending-reissue',
        name: '待补发列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/PendingReissue.vue')
      },
      {
        path: 'unquality-returns',
        name: '库内返修列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/unQualityReturns.vue')
      },
      {
        path: 'quality-returns',
        name: '待发货库内返修列表',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/QualityReturns.vue')
      }
    ]
  },
  {
    path: 'oem-baseBoard-manage',
    name: '底版管理',
    meta: {
      icon: 'icon-sl-order-manage',
      code: 'menu_goods_management'
    },
    processPlant: true, // 印花供应商
    component: ParentMenuRoute,
    children: [
      {
        path: 'baseBoard-order',
        name: '底版订单',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/BaseBoardOrder.vue')
      },
      {
        path: 'pending-receiving',
        name: '待收货',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/PendingReceiving.vue')
      },
      {
        path: 'stock-delivered',
        name: '底版入库',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/StockDelivered.vue')
      },
      {
        path: 'stock-inventory',
        name: '底版库存',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/StockInventory.vue')
      },
      {
        path: 'inventory-flow',
        name: '库存流水',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/InventoryFlow.vue')
      },
      {
        path: 'loss-apply',
        name: '损耗申请',
        meta: {
          icon: '',
          code: 'menu_goods_management'
        },
        component: () => import('@/views/oemViews/LossApply.vue')
      }
    ]
  }
]

export default ROUTES
