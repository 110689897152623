<template>
  <div class="sl-table-toolbar">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'SlTableToolbar',
  props: {

  },
  data () {
    return {

    }
  },
  mounted () {

  }
}
</script>
<style lang="scss">
.sl-table-toolbar {
  margin-bottom: 1em;
}
</style>
