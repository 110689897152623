import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer, {
  name: 'ImageViewer',
  defaultOptions: {
    title: false,
    toolbar: {
      prev: 1,
      zoomIn: 1,
      zoomOut: 1,
      reset: 1,
      play: 0,
      rotateLeft: 1,
      rotateRight: 1,
      next: 1
    }
  }
})
