// meta.code用于菜单权限控制
export const homeStaticRoutes = [
  // {
  //   path: 'index',
  //   name: '主页',
  //   meta: {
  //     icon: 'el-icon-s-home',
  //     notMenu: true
  //   },
  //   component: () => import('@/views/Index.vue')
  // }
]
