export const yesOrNo = [
  {
    label: '是',
    value: true
  }, {
    label: '否',
    value: false
  }
]

export const noSymbolValue = Symbol('无')
