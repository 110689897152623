<template>
  <div class="sl-preview-images" :style="{...containerStyle,height:imageSize.h,width:imageSize.w}">
    <ImageViewer :images="images">
      <img
        v-for="(src,index) in images"
        :key="src+index"
        :src="src"
        :alt="index+1"
        :style="{...imgStyle,maxHeight:imageSize.h,maxWidth:imageSize.w,marginBottom:imageSize.h}"
      />
      <!-- marginBottom属性是为了解决图片过小,导致第二张图片会显示一半的问题 -->
    </ImageViewer>
  </div>
</template>

<script>
export default {
  name: 'SlPreviewImages',
  components: {},
  props: {
    images: {
      type: Array,
      default: () => []
    },
    imageSize: {
      type: Object,
      default: () => {
        return {
          h: '8rem',
          w: '8rem'
        }
      }
    },
    containerStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    imgStyle: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss">
.sl-preview-images {
  position: relative;
  overflow: hidden;
}
</style>
