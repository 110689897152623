const baseURL = '/srm-common-service/'// 与后端微服务名对应
const inventoryURL = '/srm-inventory-service/' // 库存服务
const wmsURL = '/srm-wms-service/' // 仓储服务

const URL = {
  // 通过字典接口
  dictUrl: baseURL + 'data/name-value/list',
  // 库存字典接口
  inventoryDictUrl: inventoryURL + 'data/name-value/list',
  // wms字典接口
  wmsDictUrl: wmsURL + 'data/name-value/list',
  // 生成预上传链接接口
  generatePreUploadUrl: baseURL + 'aliyun-oss/upload-presigned',
  // 删除文件
  deleteFilesUrl: baseURL + 'aliyun-oss',
  // plm 分类树
  plmCategory: '/plm-product-service/plm-category/tree'
}
export default URL
